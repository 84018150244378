<script lang="ts" setup>
import { CfgButton, CfgForm, FormControlNames, useForm } from '@cfgtech/ui'
import type { CfgFormExposed, FormResults } from '@cfgtech/ui'
import type { ContactFormSchema, Schema } from '../Contact/types'
import type { FormPresetExposed } from './types'

const props = withDefaults(defineProps<{
  pending: boolean
  useInitialFocus?: boolean
  formSchema?: ContactFormSchema
  buttonLabel?: string
}>(), {
  formSchema: (): ContactFormSchema => ({
    email: { show: true, required: false },
    phone: { show: true, required: true },
  }),
})

const emit = defineEmits<{
  submit: [result: Partial<FormResults<Partial<Schema>>>]
}>()

const { t } = useI18n()
const { emailValidator, requiredValidator, phoneValidator } = useTranslatedValidators()

const formRef = ref<CfgFormExposed | null>(null)

function formSchema(): Schema {
  const schema: Schema = {} as Schema
  if (props.formSchema?.note?.show) {
    Object.assign(schema, {
      note: {
        'control': FormControlNames.Textarea,
        'placeholder': t('issue.heroSection.leadDialog.form.text'),
        'required': false,
        'value': '',
        'validators': [],
        'type': 'textarea',
        'initialFocused': props.useInitialFocus,
        'data-cy': 'note',
      },
    })
  }
  if (props.formSchema?.name?.show) {
    Object.assign(schema, {
      name: {
        'control': FormControlNames.Input,
        'placeholder': t('issue.heroSection.leadDialog.form.name'),
        'required': true,
        'value': '',
        'validators': [requiredValidator],
        'type': 'text',
        'initialFocused': props.useInitialFocus,
        'data-cy': 'name',
      },
    })
  }
  if (props.formSchema?.phone?.show) {
    Object.assign(schema, {
      phone: {
        'control': FormControlNames.Input,
        'placeholder': t('issue.heroSection.leadDialog.form.phone'),
        'required': true,
        'value': '',
        'validators': [requiredValidator, phoneValidator],
        'type': 'tel',
        'initialFocused': props.useInitialFocus,
        'data-cy': 'phone',
      },

    })
  };
  if (props.formSchema?.email?.show) {
    Object.assign(schema, {
      email: {
        'control': FormControlNames.Input,
        'placeholder': t('issue.heroSection.leadDialog.form.email'),
        'required': props.formSchema?.email.required,
        'value': '',
        'validators': [(value: string) => value ? emailValidator(value) : true, (value: string) => (props.formSchema?.email?.required ? requiredValidator(value) : true)],
        'type': 'email',
        'data-cy': 'email',
      },
    })
  }

  Object.assign(schema, {
    gdpr: {
      control: FormControlNames.Checkbox,
      value: false,
      classNames: 'gdpr',
      required: true,
      placeholder: computed(() => t('common.newsletter.form.fields.confirm.label')),
      validators: [
        (value: boolean) => value || t('common.newsletter.gdprError'),
      ],
    },
  })
  return schema
}

const { schema, results, resetData } = useForm<Partial<Schema>>(formSchema())

defineExpose({
  resetForm() {
    resetData()
    formRef.value?.resetForm()
  },
} satisfies FormPresetExposed)

function handleFormSubmit() {
  emit('submit', results.value)
}
</script>

<template>
  <CfgForm
    ref="formRef"
    class="contact-form"
    data-cy="contact-form"
    :loading="pending"
    :schema="schema"
    @submit="handleFormSubmit"
  >
    <template #submit="{ onSubmit }">
      <CfgButton
        class="mt-4 w-full py-4 lg:py-2"
        :label="buttonLabel || $t('issue.heroSection.leadDialog.form.confirm')"
        :loading="pending"
        small
        type="submit"
        variant="primary"
        @click.prevent="onSubmit"
      />
    </template>
  </CfgForm>
</template>

<style>
.contact-form .gdpr a {
  @apply text-grey-400 font-highlighted underline
}
</style>
